import React, {
     useEffect,
    useState
} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { navigate } from "@reach/router";
import {isEmpty,
    find,
} from "lodash";
import { StarberryIcons } from "@components/icons/index";
import SearchListBox from "@components/PredictiveSearch/search-multiarea";
import AreaListBox from "./AreaList";
// 
import { fadeInFromBottom } from '@components/utils/animations';

import './style.scss';
//import loadable from "@loadable/component";
//const SearchListBox = loadable(() => import("@components/PredictiveSearch/search-multiarea"));

const PropertySearch = () => {

    // 
    const [areaValResi, setAreaValResi] = useState('');

    const [selectedOption, setSelectedOption] = useState('buy');
    const [selectedType, setSelectedType] = useState('residential');
    // const [renderComponent, setRenderComponent] = useState(false);

    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    const onChangeSellRent = (e) => {
        const changeSellRent = e.target.value
        setSelectedOption(changeSellRent);
    }
    const onChangeType = (e) => {
        debugger
        const changeType = e.target.value
        setSelectedType(changeType);
        setSelectedOption(changeType === "off-plan" ? "properties" : "buy")
    }
    // Multi area search
    const [areasList, setAreasList] = useState([]);
    const [areasArr, setAreasArr] = useState([]);
    
    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }
    // Multi area search

    const submitSearch = (e) => {
        // var areaValue = areaValResi
        // var areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var developerfetch = areasArr.map((items) => items.isDeveloper === true ? items.slug.toLowerCase() : null)
        var removenulldeveloper = developerfetch.filter(function (e) {return e != null;});
        var developerValue = removenulldeveloper.map((items) => items).join("-and-")

        var searchFilterResults = "";

        if(areaValue) {
            if (selectedOption == "buy") {
                searchFilterResults = `/${selectedType === "residential" ? "" : selectedType+"-"}properties/for-sale/in-${areaValue}/`;
            }
            else if (selectedOption == "let") {
                searchFilterResults = `/${selectedType === "residential" ? "" : selectedType+"-"}properties/for-rent/in-${areaValue}/`;
            }
            else if (selectedOption == "properties") {
                searchFilterResults = `/off-plan-properties/for-sale/in-${areaValue}/`;
            }
            else if (selectedOption == "projects") {
                searchFilterResults = `/off-plan-projects/for-sale/in-${areaValue}/`;
            }
            else {
                searchFilterResults = "/properties/short-term-for-rent/in-" + areaValue + "/";
            }
        } else {
            if (selectedOption == "buy") {
                searchFilterResults = `/${selectedType === "residential" ? "" : selectedType+"-"}properties/for-sale/in-dubai/`;
            } 
            else if (selectedOption == "let") {
                searchFilterResults = `/${selectedType === "residential" ? "" : selectedType+"-"}properties/for-rent/in-dubai/`;
            }
            else if (selectedOption == "properties") {
                searchFilterResults = `/off-plan-properties/for-sale/in-dubai/`;
            }
            else if (selectedOption == "projects") {
                searchFilterResults = `/off-plan-projects/for-sale/in-dubai/`;
            }
            else {
                searchFilterResults = "/properties/short-term-for-rent/in-dubai/";
            }
        }
        if(developerValue){
            searchFilterResults += "/developer-" + developerValue + "/";
        }
        // console.log("$$$$$$--area---", areaValResi, "-----", selectedOption,"------",searchFilterResults)
        navigate(searchFilterResults);
    }
    // 

    return(
        <div className="property-search-form" variants={fadeInFromBottom}>
            <p className="mb-16 mb-lg-24 property-search-form__title">Property Search</p>
            {/* {selectedOption}-{selectedType} */}
            <Form
                // action='javascript:void(0)'
            >
                <Stack className="gap-16 align-items-md-center flex-md-row">
                    <div className="property-search-form__bg px-16 px-lg-24 py-2 py-lg-0 flex-grow-1">
                        <Stack direction="horizontal" className="gap-16 align-items-center mob-view-search-fix">
                        <div>
                                <Form.Select name="type" onChange={(e) => onChangeType(e)} className={"property-search-form__select form-select home-page-short1"}>
                                    <option value="residential">Residential</option>
                                    <option value="commercial">Commercial</option>
                                    <option value="off-plan">Off Plan</option>
                                </Form.Select>
                            </div>
                            <div className="vr" />
                            <div>
                            {selectedType === "residential" ?
                                <Form.Select name="subtype" onChange={onChangeSellRent} className={selectedOption === "shortterm" ? "property-search-form__select home-page-short" : "property-search-form__select"}>
                                    <option value="buy">For Sale</option>
                                    <option value="let">For Rent</option>
                                    <option value="shortterm">Short Term</option>
                                </Form.Select>
                                : selectedType === "commercial" ?
                                <Form.Select name="subtype" onChange={onChangeSellRent} className={"property-search-form__select"}>
                                <option value="buy">For Sale</option>
                                <option value="let">For Rent</option>                                
                            </Form.Select>
                            : selectedType === "off-plan" ?                                 
                            <Form.Select name="subtype" onChange={onChangeSellRent} className={"property-search-form__select"}>
                            <option value="properties">Properties</option>
                            <option value="projects">Projects</option>
                            
                        </Form.Select> : null}
                            </div>
                            <div className="vr" />
                            <div className="find-property-location-search location-search-residential">
                                <SearchListBox
                                    areaVal={areaValResi}
                                    placeholder={"Location"}
                                    setAreaVal={setAreaValResi}
                                    setAreasList={setAreasList}
                                    handleMultiAreaFun={handleMultiAreaFun}
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}}
                                />
                                {/* <SearchResultBox areaVal={areaValResi} setAreaVal={setAreaValResi} placeHolder={`City, Community or Tower`} department={"residential"} /> */}
                                {/* <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="City, Community or Tower" />
                                </Form.Group> */}
                            </div>
                        </Stack>
                    </div>
                    <Button variant="secondary" type="button" onClick={submitSearch} className="submit-btn">
                        <span className="d-none d-md-inline"><StarberryIcons iconName="searchIcon" className={'searchIcon'} /></span>
                        <span className="d-md-none">Search</span>
                    </Button>
                </Stack>
            </Form>
            <div className="predictive-arelist-suggestion">
                <AreaListBox areas={areasArr} removeArea={removeArea} />
            </div>
        </div>
    )
}

export default PropertySearch

