import React, {useState} from "react"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { StarberryIcons } from "@components/icons/index";

import { motion } from "framer-motion";
import { fadeInFromBottom } from '@components/utils/animations';
import { navigate } from "@reach/router";
import './style.scss';

const MotionForm = motion(Form);

const PropertyWorth = () => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [valemailError, setValEmailError] = useState(false)
    const onChange = (e) => {
            setEmail(e.target.value)
            if (e.target.value === "") {
              setEmailError(true)
            }
            else if (e.target.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
              setValEmailError(true)
              setEmailError(false)
            }
            else {
              setEmailError(false)
              setValEmailError(false)
            }
    }
    const submitval = (e) => {
       if(!email || email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null){
        if(!email){
          setEmailError(true)
        }
        if(email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null){
          setValEmailError(true)
        }
      }
      else {
          sessionStorage.setItem('email', email)
        navigate('/sell-your-property-in-uae/property-valuation-in-dubai')
      }
    }
    return(
        <MotionForm className="property-worth-form" variants={fadeInFromBottom}>
            <p className="mb-16 mb-lg-24 property-worth-form__title">What’s my Property Worth?</p>
            <Stack className="gap-16 align-items-md-center flex-md-row">
                <Form.Group className="flex-grow-1" controlId="formBasicEmail">
                    <Form.Control onChange={onChange} className={emailError ? 'error-border' : valemailError ? 'error-border' : ''} name="email" type="email" placeholder="Email Address" />
                    {emailError &&
                            <div className="error">
                              Please Enter Email.
                            </div>
                          }
                          {valemailError && !emailError &&
                            <div className="error">
                              Please Enter Valid Email.
                            </div>
                          }
                </Form.Group>

                <Button onClick={() => submitval()} variant="secondary" className="submit-btn">
                    <span className="d-none d-md-inline"><StarberryIcons iconName="rightArrowIcon" className="rightArrowIcon" /></span>
                    <span className="d-md-none">Get Started</span>
                </Button>
            </Stack>
        </MotionForm>
    )
}

export default PropertyWorth