import React from 'react';
import Stack from 'react-bootstrap/Stack';
import { StaticImage } from "gatsby-plugin-image";
import ModuleText from '@components/modules/Text';

import "./style.scss";

const GoogleRating = ({ affix, review, className, GoogleReview_Content, showGoogleLogo }) => {
    return(
        <Stack gap={24} direction="horizontal" className={`google-reviews ${className}`}>
            {showGoogleLogo &&
                <StaticImage
                src="../../images/google-reviews.png"
                width={80}
                quality={32}
                formats={["auto", "webp"]}
                alt="Google Rating"
                />
            }
            {
                GoogleReview_Content ? 
                    <ModuleText
                        text={GoogleReview_Content}
                        className="google_review__text"
                    />
                :
                <p>{review && review} {affix && affix}</p>
            }
        </Stack>
    )
}

export default GoogleRating