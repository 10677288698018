import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const gatsbyImage = ({ g_image, alt, className, s_width, s_height, s_loading="lazy" }) => {
  const Image = g_image && getImage(g_image)
  return (
    <>
      {Image ? (
        <GatsbyImage image={Image} alt={alt} className={className} loading={s_loading} />
      ) : (
        <img src={"../../images/no-image.png"} alt={alt} className={className} width={s_width} height={s_height} loading={"lazy"} />
      )}
    </>
  )
}
export default gatsbyImage
