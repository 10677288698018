import React, { useState, useRef } from "react"
import { StarberryIcons } from "@components/icons/index"
import Button from "react-bootstrap/Button"
import { Modal } from "react-bootstrap"
import "react-modal-video/scss/modal-video.scss"
//import ReactPlayer from "react-player"
import ReactPlayer from 'react-player/lazy'
//import getVideoId from "get-video-id"
import "./lightbox.scss"
import { isIOS, isMobile } from "react-device-detect"

//const ReactPlayer = loadable(() => import("react-player"))

const Lightbox = ({
  btnVariant,
  videoUrl,
  hideVideoIcon,
  className,
  children,
}) => {
  const vidRef = useRef(null)
  //const videoid = videoUrl && getVideoId(videoUrl[0])
  const variant = btnVariant ? btnVariant : "none"
  const [isPlay, setPlay] = useState(false)
  const [mute, setMute] = useState(true)
  if (isPlay) {
    const url = typeof window !== "undefined" ? window.location.href : ""
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: url,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }
  return (
    <React.Fragment>
      <Button
        className={`video-lightbox${className ? " " + className : ""}`}
        onClick={() => setPlay(true)}
        variant={variant}
      >
        {children}
        {!hideVideoIcon && (
          <div className="video-lightbox-circle-icon">
            <StarberryIcons iconName="videoIcon" className="video-icon" />
          </div>
        )}
      </Button>
      <Modal
        show={isPlay}
        onHide={e => setPlay(false)}
        dialogClassName="modal-video"
        aria-labelledby="example-custom-modal-styling-title"
        backdropClassName="video-backdrop"
        className="area-guide-full-video-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            ref={vidRef}
            url={videoUrl && videoUrl[0]}
            modestbranding="1"
            controls={isMobile && isIOS ? true : true}
            muted={isMobile && isIOS ? mute : false}
            autoplay={true}
            playsinline={true}
            playing={isPlay}
            onEnded={() => {
              setPlay(false)
            }}
            width="100%"
            height="100%"
            previewTabIndex={0}
            light={false}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Lightbox
